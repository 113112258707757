/* eslint-disable */
import MessageTriggerHandler from '@/classes/Core/WebSocketClient/TriggerHandlers/MessageTriggerHandler'
import InbandTriggerHandler  from '@/classes/Core/WebSocketClient/TriggerHandlers/InbandTriggerHandler'
import ObjectTriggerHandler  from '@/classes/Core/WebSocketClient/TriggerHandlers/ObjectTriggerHandler'
import SyncTriggerHandler    from '@/classes/Core/WebSocketClient/TriggerHandlers/SyncTriggerHandler'

export default class WebSocketClient
{
    constructor( core )
    {

        if( !WebSocketClient.instance )
        {
            /*
                window.forceOffline = true
             */

            this.getState = ( key ) =>
            {
                return core.getState( key )
            }

            this.config = core.getConfig()
            this.store = core.getStore()
            this.auth = core.getAuthenticator()
            this.uuid = core.getUuid()
            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.eventManager = core.getEventManager()
            this.logger = core.getLogger()
            this.crypto = core.getCryptoHelper()
            this.f = core.f()

            this.timer = core.getCoreTimer()
            this.lastTick = 0
            this.tickCount = 0
            this.halt = false

            this.timeout = 30000
            this.recovery = 3000

            this.connected = false
            this.resetted = false
            this.processingState = false

            this.maxMessageSize = ( 1024 * 1024 )

            this.resetTimer = null
            this.queue = {}
            this.chunks = {}

            this.connectedBefore = false
            this.setup()

            this.handlers = {}
            this.initHandlers( core )

            this.eventManager.add( 'on-auth-ready', () =>
            {
                this.auth = core.getAuthenticator()
            } )

            this.storeIndex = this.eventManager.addIndexed( 'on-store-initialized', () =>
            {
                this.setTriggerable()
            } )

            this.resetIndex = this.eventManager.addIndexed( 'core-component-reset', () =>
            {

                this.reset()

            } )

            this.eventManager.addIndexed( 'on-connection-state-changed', ( state ) =>
            {
                this.handleConnectionStateChange( state )
            } )

            WebSocketClient.instance = this

        }

        return WebSocketClient.instance

    }

    destruct()
    {
        this.eventManager.removeIndexedCallback( 'on-store-initialized', this.storeIndex )
        this.eventManager.removeIndexedCallback( 'core-component-reset', this.resetIndex )
        this.halt = true
        this.client.close()
        delete WebSocketClient.instance
    }

    initHandlers( core )
    {
        this.handlers = {
            message: false, //new MessageTriggerHandler( core ),
            object : new ObjectTriggerHandler( core, this ),
            sync   : new SyncTriggerHandler( core ),
            inband : new InbandTriggerHandler( core )
        }
    }

    setup()
    {

        this.logger.clog( 'WebSocketClient.setup', 'setting up new wss connection' )
        this.client = new WebSocket( this.config.socketUrl, 'json' )
        this.logger.cdebug( 'WebSocketClient.setup', 'url: ', this.config.socketUrl, 'client: ', this.client )

        this.client.onmessage = ( message =>
        {
            this.handleMessage( message )
        } )

        this.client.onopen = ( () =>
        {
            this.settingUp = false
            this.connectedBefore = true
            this.processingState = false
            this.resetted = false
            this.heartbeat( 'open' )
            this.checkReauth()
                .then( () =>
                {
                    this.store.commit( 'setOnline', true )
                    this.eventManager.dispatchNamed( 'on-websocket-connection-state-change' )
                    this.eventManager.dispatch( 'on-websocket-open' )
                    this.eventManager.dispatch( 'on-websocket-resurrection' )
                    this.eventManager.dispatchAndRemove( 'after-websocket-reset' )
                } )

        } )
        this.client.onclose = ( ( closeEvent ) =>
        {
            this.eventManager.dispatchNamed( 'on-websocket-connection-state-change' )
            this.eventManager.dispatch( 'on-websocket-close' )

        } )
        this.client.onerror = ( error =>
        {
            this.handleError( error )
        } )

    }

    killClient()
    {

        this.store.commit( 'setOnline', false )
        this.connected = false
        if( null !== this.client )
        {
            this.client.onerror = () =>
            {
            }
            this.client.onclose = () =>
            {
            }
            this.client.close()

            delete this.client
            this.client = null
        }

    }

    checkReauth()
    {
        return new Promise( resolve =>
        {
            if( true === this.store.getters.offlineAuthorized )
            {
                this.logger.clog( 'WebSocketClient.checkReauth', 'reauthorization needed: starting flow.' )
                this.auth
                    .reauthorizeOfflineSession()
                    .then( () =>
                    {
                        this.logger.clog( 'WebSocketClient.checkReauth', 'reauthorization done.' )
                        return resolve()
                    } )
                    .catch( () =>
                    {
                        this.auth.logout()
                    } )
            }
            else
            {
                this.logger.clog( 'WebSocketClient.checkReauth', 'no reauthorization needed.' )
                return resolve()
            }
        } )
    }

    reset()
    {

        if( this.connectedBefore )
        {

            this.logger.clog( 'WebSocketClient.reset', 'resetting ws connection' )
            this.halt = true
            this.killClient()
            this.store.commit( 'setOnline', null )
            let timeout = this.connectedBefore ? this.recovery + 1000 : 2000

            this.logger.clog( 'WebSocketClient.reset', 'ws connection closed and client killed... starting over in approx. ' + timeout + ' ms...' )

            this.timer.addTimeout( 'websocket-reset', timeout, () =>
            {

                this.halt = false
                this.connectedBefore = true
                this.setup()

            } )

        }
        else
        {
            this.store.commit( 'setOnline', false )
            this.setup()
        }

    }

    handleError( error )
    {

        if( null !== this.client
            && undefined !== this.client
            && !this.halt )
        {

            this.logger.clog( 'WebSocketClient.handleError', 'connection state is ' + this.client.readyState, error )

            if( 3 === this.client.readyState )
            {
                this.reset()
            }

        }

    }

    ping( retry )
    {

        this.logger.clog( 'WebSocketClient.ping', 'sending ping request | force:', retry )
        return new Promise( resolve =>
        {

            if( this.connected
                || undefined !== retry )
            {
                this.logger.cdebug( 'WebSocketClient.ping', 'pinging...' )
                this.request( {
                    method      : 'ping',
                    looseSession: true
                }, 2000 ).then( response =>
                {
                    this.logger.csuccess( 'WebSocketClient::heartbeat', 'ping response received' )
                    this.store.commit( 'setUpdateVersion', response.version )
                    return resolve()
                } )
            }

        } )
    }

    setTriggerable()
    {

        this.logger.cdebug( 'WebSocketClient::setTriggerable', this.connected, this.store.getters.authorized )
        if( this.connected
            && this.store.getters.authorized === true )
        {
            this.request( {
                    method: 'network.setTriggerable'
                } )
                .then( () =>
                {
                    this.logger.csuccess( 'WebSocketClient::setTriggerable', 'connection refreshed' )
                } )
        }
    }

    heartbeat( which )
    {

        let mustValidate = this.store.getters.authorized === true
        this.store.commit( 'setOnline', true )

        if( window.forceOffline )
        {
            this.connected = false
            this.store.commit( 'setOnline', false )
            return
        }

        if( 'open' === which )
        {
            this.logger.csuccess( 'WebSocketClient::heartbeat', 'connection opened' )
            this.connected = true
            this.ping( true )
                .then( () =>
                {

                    this.eventManager.dispatch( 'onSocketConnect' )
                    this.setTriggerable()

                } )
        }

        if( 'tick' === which )
        {

            this.logger.clog( 'WebSocketClient.heartbeat', which )
            if( this.store.getters.authorized === true )
            {
                this.store.commit( 'setOfflineFailures', 0 )
            }

            this.tickCount++
            this.lastTick = Date.now()

            if( 3 === this.tickCount )
            {
                this.sendMeta()
            }
            else if( 10 === this.tickCount )
            {
                this.tickCount = 0
            }

            this.eventManager.dispatch( 'tick-state' )

            let lastTick = Date.now()
            let now = Date.now()
            if( null !== this.store.getters.lastTick )
            {
                lastTick = this.store.getters.lastTick
            }

            if( ( now - lastTick ) < this.config.maxTickTimeout
                || !mustValidate )
            {
                this.store.commit( 'setLastTick', Date.now() )
                this.eventManager.dispatch( 'on-wss-tick' )
            }
            else
            {
                if( ( now - lastTick ) < this.config.maxTickTimeout
                    && mustValidate )
                {
                    this.eventManager.dispatch( 'on-session-timeout' )
                }
            }

        }

    }

    sendMeta()
    {

        if( this.connected
            && true === this.store.getters.authorized )
        {
            this.request( {
                method    : 'device.storeMeta',
                appVersion: this.config.version
            } )
        }

    }

    requestOnConnect( requestMessage, timeout )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( !this.connected )
            {
                this.eventManager.add( 'onSocketConnect', () =>
                {

                    this.timer.removeInterval( 'on-connection-timeout' )

                    this.request( requestMessage, timeout, true )
                        .then( result =>
                        {
                            return resolve( result )
                        } )
                        .catch( error =>
                        {
                            return reject( error )
                        } )

                } )

                this.timer.addTimeout( 'on-connection-timeout',
                    ( timeout * 2 ),
                    () =>
                    {

                        return reject( 'ERR_TIMEOUT' )

                    }, ( timeout * 2 ) )
            }
            else
            {
                this.request( requestMessage, timeout, true )
                    .then( result =>
                    {
                        return resolve( result )
                    } )
                    .catch( error =>
                    {
                        return reject( error )
                    } )
            }

        } )

    }

    _chunkString( str, length )
    {
        return str.match( new RegExp( '.{1,' + length + '}', 'g' ) )
    }

    performChunked( messageUuid, method, requestMessage, timeout, prepared )
    {

        return new Promise( resolve =>
        {

            let chunks   = this._chunkString( requestMessage, this.maxMessageSize ),
                promises = []

            for( let c in chunks )
            {

                let chunk = chunks[ c ]

                let message = {

                    method             : 'chunked:' + method,
                    messageId          : this.uuid.generate(),
                    originalMessageUuid: messageUuid,
                    chunkId            : c,
                    totalChunks        : chunks.length,
                    payload            : chunk

                }

                console.log( 'SHR > CHUNK', method )

                promises.push( () =>
                {

                    return this.performRequest( JSON.stringify( message ), timeout, prepared, message.messageId )
                               .then( response =>
                               {
                                   this.logger.clog( 'WebSocketClient.request.performChunked', 'sent chunk', c, 'of', chunks.length )
                               } )

                } )

            }

            this.queue[ messageUuid ] = 'chunkedMessage'

            this.eventManager.add( 'onSocketMessage-' + messageUuid, ( response ) =>
            {

                this.timer.removeTimeout( 'socket-timeout-' + messageUuid )
                this.eventManager.remove( 'onSocketTimeout-' + messageUuid )
                return resolve( this.handleResponse( response ) )

            } )

            this.eventManager.add( 'onSocketTimeout-' + messageUuid, ( response ) =>
            {

                this.timer.removeTimeout( 'socket-timeout-' + messageUuid )
                this.eventManager.remove( 'onSocketTimeout-' + messageUuid )
                this.logger.cdump( 'WebSocketClient.request', 'message was', this.queue[ messageUuid ] )
                this.logger.cerror( 'WebSocketClient.request', 'timeout for message ' + messageUuid + ' after ' + ( timeout !== undefined ? timeout : this.timeout ) + ' millis for ', JSON.stringify( this.queue[ messageUuid ] ) )
                this.eventManager.remove( 'onSocketMessage-' + messageUuid )
                return resolve( this.handleResponse( response ) )

            } )

            this.timer.addTimeout( 'socket-timeout-' + messageUuid,
                ( timeout !== undefined ? timeout : this.timeout ),
                () =>
                {
                    this.eventManager.dispatchAndRemove( 'onSocketTimeout-' + messageUuid, { state: false } )
                } )

            this.f.promiseRunner( promises )
                .then( () =>
                {
                    this.logger.clog( 'WebSocketClient.request.performChunked', 'finalized chunked request' )
                } )

        } )

    }

    performRequest( requestMessage, timeout, prepared, chunked )
    {

        return new Promise( resolve =>
        {

            chunked = chunked || false

            let message,
                method,
                messageUuid

            if( !chunked )
            {

                if( undefined === prepared )
                {
                    requestMessage.sessionId = this.store.getters.idSession
                    requestMessage.appVersion = this.config.version
                }

                messageUuid = this.uuid.generate()
                requestMessage.messageId = messageUuid

                method = requestMessage.method
                message = JSON.stringify( requestMessage )

            }
            else
            {
                message = requestMessage
                messageUuid = chunked
            }

            if( message.length > this.maxMessageSize
                && !chunked )
            {

                return resolve( this.performChunked( messageUuid, method, message, timeout, prepared ) )

            }
            else
            {

                this.queue[ messageUuid ] = requestMessage
                this.eventManager.add( 'onSocketMessage-' + messageUuid, ( response ) =>
                {

                    this.timer.removeTimeout( 'socket-timeout-' + messageUuid )
                    this.eventManager.remove( 'onSocketTimeout-' + messageUuid )
                    return resolve( this.handleResponse( response ) )

                } )

                this.eventManager.add( 'onSocketTimeout-' + messageUuid, ( response ) =>
                {

                    this.logger.cdump( 'WebSocketClient.request', 'message was', this.queue[ messageUuid ] )
                    this.logger.cerror( 'WebSocketClient.request', 'timeout for message ' + messageUuid + ' after ' + ( timeout !== undefined ? timeout : this.timeout ) + ' millis for ', JSON.stringify( this.queue[ messageUuid ] ) )
                    this.eventManager.remove( 'onSocketMessage-' + messageUuid )
                    return resolve( this.handleResponse( response ) )

                } )

                this.timer.addTimeout( 'socket-timeout-' + messageUuid,
                    ( timeout !== undefined ? timeout : this.timeout ),
                    () =>
                    {
                        this.eventManager.dispatchAndRemove( 'onSocketTimeout-' + messageUuid, { state: false } )
                    } )

                this.client.send( message )

            }

        } )

    }

    isLoginMethod( message )
    {

        let methods = [
            'ping',
            'users.tryLogin',
            'users.verifyLoginToken',
            'users.importUUID'
        ]
        return -1 < methods.indexOf( message.method )

    }

    waitForAuthComponent()
    {
        return new Promise( resolve =>
        {

            if( undefined !== this.auth )
            {
                return resolve()
            }
            else
            {
                this.timer.addTimeout( 'websocket-wait-for-auth', 500, () =>
                {

                    return resolve( this.waitForAuthComponent() )

                } )
            }

        } )
    }

    signMessage( requestMessage )
    {

        let plain  = JSON.stringify( requestMessage ),
            signed = this.crypto.sign( plain )
    }

    request( requestMessage, timeout, prepared, debug )
    {

        return new Promise( ( resolve, reject ) =>
        {

            /* TODO: implement signatures -> this.signMessage( requestMessage )*/

            if( window.forceOffline )
            {
                this.connected = false
                this.store.commit( 'setOnline', false )
                return reject()
            }
            else
            {
                this.connected = null !== this.client && undefined !== this.client && this.client.readyState === 1
            }

            if( this.connected
                && !this.processingState
                && null !== this.client
                && 1 === this.client.readyState )
            {

                if( this.store.getters.offlineAuthorized === true
                    && !this.isLoginMethod( requestMessage ) )
                {
                    this.waitForAuthComponent()
                        .then( () =>
                        {
                            this.auth.reauthorizeOfflineSession()
                                .then( response =>
                                {
                                    if( 'WAIT' !== response )
                                    {
                                        this.performRequest( requestMessage, timeout, prepared )
                                            .then( result =>
                                            {

                                                return resolve( result )

                                            } )
                                    }
                                    else
                                    {
                                        this.logger.cdebug( 'WebSocketClient.request', 'reauthorization processing: hang on...' )
                                        return reject( 'PROCESSING' )
                                    }
                                } )
                                .catch( () =>
                                {
                                    return reject( 'ERR_FAILED_REAUTH' )
                                } )
                        } )
                }
                else
                {
                    this.performRequest( requestMessage, timeout, prepared )
                        .then( result =>
                        {
                            return resolve( result )
                        } )
                        .catch( e =>
                        {
                            return reject( e )
                        } )
                }

            }
            else
            {
                if( null !== this.client
                    && 3 === this.client.readyState )
                {
                    this.reset()
                }
                return reject( 'ERR_NOT_CONNECTED' )
            }

        } )

    }

    handleResponse( response )
    {

        return new Promise( ( resolve, reject ) =>
        {

            delete this.queue[ response.messageId ]
            if( response.state !== false )
            {
                return resolve( response )
            }
            else
            {
                if( 'SESSION_INVALID' === response.errorCode )
                {
                    this.logger.cerror( 'WebSocketClient:handleResponse', 'received SESSION_INVALID from server' )
                }
                else
                {
                    this.logger.cdebug( 'WebSocketClient:handleResponse', 'error reported by remote: ' + response.errorCode + ' (' + response.errorMessage + ')' )
                    let code       = response.errorCode !== undefined ? response.errorCode : 'WSS_INVALID_RESPONSE',
                        additional = response.additionalData

                    if( undefined !== additional )
                    {
                        return reject( [ code, additional ] )
                    }
                    else
                    {
                        return reject( code )
                    }

                }
            }

        } )

    }

    parseHandler( method )
    {
        let parts = method.split( '.' )
        return {
            handler: parts[ 0 ],
            method : parts[ 1 ]
        }
    }

    prepareTimestamps( data )
    {

        for( let p in data.payload )
        {
            if( -1 < p.indexOf( 'datetime' ) )
            {
                if( null !== data.payload[ p ] )
                {

                    let tsmp = new Date( data.payload[ p ] )
                    data.payload[ p ] = this.friendlyTimestamp.mysqlTimestamp( tsmp.getTime() )

                }
            }
        }

        return data

    }

    handleTrigger( data )
    {

        if( true !== this.getState( 'inYearChange' ) )
        {

            let handlerData = this.parseHandler( data.method )
            let handlerName = handlerData.handler
            let method = handlerData.method

            data = this.prepareTimestamps( data )

            this.handlers[ handlerName ][ method ]( data )

        }

    }

    _dispatchMessage( data, payload )
    {

        if( undefined !== data.messageId
            && undefined !== this.queue[ data.messageId ] )
        {

            let response = data
            response.messageTimestamp = payload.timestamp

            this.eventManager.dispatchAndRemove( 'onSocketMessage-' + data.messageId, response )

            delete this.queue[ data.messageId ]

        }
    }

    handleMessage( messageEvent, retry )
    {

        let payload = JSON.parse( messageEvent.data )
        this.heartbeat( payload.method )

        switch( payload.state )
        {
            case true:
                if( 'progress' === payload.method )
                {
                    this.eventManager.dispatch( 'on-progress-message', payload.data )
                }
                if( 'collab-channel-stats' === payload.method )
                {
                    this.eventManager.dispatch( 'collab-channel-stats', payload.data )
                    return
                }
                if( 'collab-channel-data' === payload.method )
                {
                    this.eventManager.dispatch( 'collab-channel-data', payload.data )
                    return
                }
                if( undefined !== payload.data
                    && 'tick' !== payload.method )
                {
                    try
                    {
                        if( true !== payload.plain )
                        {
                            this.crypto.plainDecrypt( payload.data )
                                .then( data =>
                                {
                                    this._dispatchMessage( JSON.parse( data ), payload )
                                } )
                        }
                        else
                        {
                            this._dispatchMessage( payload.data, payload )
                        }
                    }
                    catch( error )
                    {
                        if( !retry )
                        {
                            this.timer.addTimeout( 'on-socket-catch-' + Date.now(),
                                1000,
                                () =>
                                {
                                    this.handleMessage( messageEvent, true )
                                } )
                        }
                        else
                        {
                            this.logger.cerror( 'WebSocketClient.handleMessage', 'message failure: ', error )
                            this.eventManager.dispatchAndRemove( 'onSocketMessage-' + payload.data.messageId, { state: false } )
                        }
                    }
                }
                break
            case false:
                this.logger.cerror( 'WebSocketClient.handleMessage', 'websocket call was unsuccessful', payload.method, payload.messageId )
                if( undefined !== payload.messageId
                    && undefined !== this.queue[ payload.messageId ] )
                {

                    let response = undefined !== payload.data ? payload.data : payload
                    response.messageTimestamp = payload.timestamp
                    if( undefined !== payload.errorMessage )
                    {
                        response.errorMessage = payload.errorMessage
                        if( 'SESSION_INVALID' === payload.errorMessage )
                        {
                            this.logger.cerror( 'WebSocketClient.handleMessage', 'invalid session detected: logging out.' )
                            this.eventManager.dispatch( 'on-session-timeout' )
                        }
                    }
                    this.eventManager.dispatchAndRemove( 'onSocketMessage-' + payload.messageId, response )

                }
                break
            default:
                if( true === payload.isTrigger )
                {
                    this.crypto.plainDecrypt( payload.payload )
                        .then( data =>
                        {
                            this.handleTrigger( JSON.parse( data ) )
                        } )
                }
                else
                {
                    this.logger.cerror( 'WebSocketClient.handleMessage', 'undefined message type' )
                }
                break
        }

    }

    handleConnectionStateChange( state )
    {
        this.logger.clog( 'WebSocketClient.handleConnectionStateChange', state )
        switch( state )
        {
            case 'offline':
                this.halt = true
                this.store.commit( 'setOnline', false )
                this.connected = false
                this.killClient()
                break
            case 'online':
                this.reset()
                break
        }
    }

}
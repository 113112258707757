<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div class="header-functions-wrapper" :style="'min-height:'+absoluteHeight+'px;'" v-if="ready"
             :key="'headFunctions-'+ownId">
            <div class="head-functions" :id="id">
                <template v-if="!$core.getLicense().forceReadOnly()">
                    <div id="more-functions" style="float:right;"></div>
                    <Button v-if="true === has.add && !isArchivedScope
                        && $core.getLicense().isUnrestricted( 'has', 'add' )
                        && ( '' === objectType || $core.getLicense().isAllowed( 'page_' + objectType ) )"
                            @clicked="handleClick( 'click', 'add' )"
                            :key="'top-adder-'+objectType"
                            type="add"
                            addClass="large"/>
                    <Button v-if="true === has.qr && $core.getLicense().isAllowed( 'page_colleagues' )
                        && $core.getLicense().isUnrestricted( 'has', 'qrkey' )"
                            @clicked="handleClick( 'qtkey' )"
                            type="key"
                            :title="$core.getTranslation().translate( 'button-title-key' )"
                            addClass="large"/>
                    <Button v-if="true === has.print && $core.getLicense().isAllowed( 'print' )
                        && $core.getLicense().isUnrestricted( 'has', 'print' )"
                            @clicked="handleClick( 'print' )"
                            type="print"
                            :title="$core.getTranslation().translate( 'button-title-print' )"
                            :addClass="'large'+( hiddenVisible ? ' active' : '' )"/>
                    <Button v-if="true === has.print && $core.getLicense().isAllowed( 'print' )
                        && $core.getLicense().isUnrestricted( 'has', 'excel' )"
                            @clicked="handleClick( 'excel' )"
                            type="excel"
                            :title="$core.getTranslation().translate( 'button-title-excel' )"
                            :addClass="'large'+( hiddenVisible ? ' active' : '' )"/>
                    <Button v-if="true === has.toggleHiddenVisible
                        && $core.getLicense().isUnrestricted( 'has', 'toggleHiddenVisible' )"
                            @clicked="handleClick( 'toggleHiddenVisible' )"
                            type="hide"
                            :title="$core.getTranslation().translate( 'button-title-show-hidden'+( hiddenVisible ? '-active' : '' ) )"
                            :addClass="'large'+( hiddenVisible ? ' active' : '' )"/>
                    <Button v-if="true === has.search
                        && $core.getLicense().isUnrestricted( 'has', 'search' )"
                            @clicked="handleClick( 'search' )"
                            type="search"
                            addClass="large"/>
                    <ElementHandler v-if="true === has.select
                                      && $core.getLicense().isUnrestricted( 'has', 'select' )"
                                    :selectMode="selectMode"
                                    :selectCount="selectCount"
                                    :selected="selected"
                                    :viewItem="viewItem"
                                    :objectType="objectType"
                                    :scope="isArchivedScope ? 'archive' : 'cache'"
                                    :key="'top-element-handler-'+objectType"
                                    @handledElements="raiseFilters( '_refresh' )"
                                    @reset="handleClick( 'resetSelection' )"
                                    @clicked="handleClick( 'click', 'startSelect' )"/>
                </template>
                <template v-if="!hideFilters">
                    <template v-for="filterComponent in preparedFilters"
                              :key="'filter-'+filterComponent.filter+'-'+filterKey">
                        <component v-if="$core.getLicense().isUnrestricted( 'filters', filterComponent.filter )"
                                   :archived="isArchivedScope"
                                   :title="filterComponent.title"
                                   :is="filterComponent.filter"
                                   :own="filterComponent.own"
                                   :list="filterComponent.list"
                                   @filter="raiseFilters"/>
                    </template>
                    <div class="clearfix"></div>
                </template>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </transition>
</template>

<script>
import ElementHandler        from '@/components/elements/defaults/ElementHandler'
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup.vue";

export default {

    name      : 'HeadFunctions',
    components: { ElementHandler },
    mixins    : [ MixinComponentCleanup ],
    props     : {
        has             : { Type: Object, required: true },
        selectMode      : { Type: Boolean, required: false, default: false },
        selectCount     : { Type: Number, required: false, default: 0 },
        selected        : { Type: Object, required: false, default: {} },
        hiddenVisible   : { Type: Boolean, required: false, default: false },
        isOwnScope      : { Type: Boolean, required: false, default: false },
        isArchivedScope : { Type: Boolean, required: false, default: false },
        hideFilters     : { Type: Boolean, required: false, default: false },
        attach          : { Type: Boolean | String, required: false, default: false },
        objectType      : { Type: String, required: false, default: '' },
        viewItem        : { Type: String, required: false, default: '' },
        id              : { Type: String, required: false, default: '' },
        availableFilters: { Type: Array, required: false, default: [] }
    },

    data()
    {
        return {
            ownId          : this.$core.getUuid().generate(),
            absoluteHeight : 0,
            preparedFilters: [],
            headerKey      : '',
            filterKey      : '',
            ready          : false
        }
    },

    emits: [ 'click', 'search', 'filter', 'toggleHiddenVisible', 'print', 'excel', 'resetSelection', 'qrkey' ],

    watch: {
        availableFilters: {
            immediate: true,
            deep     : true,
            handler( newValue, oldValue )
            {

                if( undefined !== oldValue
                    && undefined !== newValue )
                {

                    let hashA = this.$core.f().objectHash( newValue, true ),
                        hashB = this.$core.f().objectHash( oldValue, true )

                    if( hashA !== hashB )
                    {
                        this.preparedFilters = []
                        this.prepareFilters()
                        this.filterKey = hashA
                    }

                }

            }
        }
    },

    created()
    {
        this.$core.getLicense()
            .waitReady()
            .then( () =>
            {
                this.headerKey = '' !== this.$props.id ? this.$props.id : 'header-' + this.$core.getUuid().generate()
                this.prepareFilters()
            } )
    },

    updated()
    {
        this.$core.getLicense()
            .waitReady()
            .then( () =>
            {
                this.applyHeight()
            } )
    },

    mounted()
    {
        this.$core.getLicense()
            .waitReady()
            .then( () =>
            {

                this.$nextTick()
                    .then( () =>
                    {
                        if( false !== this.$props.attach )
                        {
                            this.attachFunctionBox()
                        }
                        this.ready = true
                    } )

            } )
    },

    beforeUnmount()
    {
        if( false !== this.$props.attach )
        {
            this.$core.getUi().getFloatingFunctionBox().detachFromView( this.attach )
        }
    },

    methods: {

        handleClick( emit, which )
        {
            let statsKey = emit + ( undefined !== which ? '-'+which : '' )
            this.$core.s().count( 'clicked-headfunction-'+statsKey )
            this.$emit( emit, which )
        },

        prepareFilters()
        {
            for( let f in this.$props.availableFilters )
            {
                let filter = this.$props.availableFilters[ f ]
                if( filter instanceof Object )
                {
                    this.preparedFilters.push( {
                        filter: filter.filter,
                        title : filter.title,
                        own   : filter.own,
                        list  : filter.list || undefined
                    } )
                }
                else
                {
                    this.preparedFilters.push( {
                        filter: filter,
                        own   : this.$core.settings().getSetting( 'combineShares' ) === true ? false : this.$props.isOwnScope
                    } )
                }
            }
        },

        raiseFilters( type, filters )
        {
            this.$emit( 'filter', type, filters )
        },

        applyHeight()
        {
            let element = document.querySelector( '#' + this.headerKey )
            if( null !== element )
            {
                this.$nextTick()
                    .then( () =>
                    {
                        this.absoluteHeight = element.offsetHeight || this.absoluteHeight
                    } )
            }
        },

        attachFunctionBox()
        {
            if( document.querySelector( '#' + this.headerKey ) !== null )
            {
                this.$core.getUi().getFloatingFunctionBox().attachToView( this.attach )
            }
            else
            {
                setTimeout( () =>
                {
                    this.attachFunctionBox()
                }, 100 )
            }
        }

    }

}
</script>
import { reactive }       from "vue";
import Resolver           from "@/objects/abstract/helpers/Resolver";
import PersonalAttributes from "@/objects/abstract/helpers/PersonalAttributes";
import CollectorClass     from "@/objects/abstract/helpers/CollectorClass";

export default class AbstractObjectClass
{

    constructor( core, type, refList, personalAttributesStatesList, ignoreListEditorState )
    {

        this.logger = core.getLogger()
        this.database = core.getDatabase()
        this.cryptoHelper = core.getCryptoHelper()
        this.eventManager = core.getEventManager()
        this.store = core.getStore()
        this.queueWorker = core.getQueueWorker()
        this.uuid = core.getUuid()
        this.functions = core.f()
        this.statistics = core.s()

        this.settings = core.settings()
        this.sorter = core.getSorter()
        this.collector = new CollectorClass( core )

        this.t = ( key ) =>
        {
            return core.t( key )
        }

        this.ignoreListEditorState = ignoreListEditorState || false

        this.getState = ( key ) =>
        {
            return core.getState( key )
        }

        this.iter = 0
        this.stateMergers = core.getStateMergers()
        this.lazyObject = core.lazyObjects()

        this.eventManager.add( 'onObjectHasherReady', ( hasher ) =>
        {
            this.lazyObject = hasher
        } )

        this.eventManager.add( 'on-after-update-merge-' + type, ( delayedHash ) =>
        {
            this._handleAfterUpdateTriggered( delayedHash )
        } )

        this.resolver = new Resolver( this.database, this.functions )
        this.personalAttributes = new PersonalAttributes( this.logger, this.functions, this.eventManager, this.database )

        this.isStudent = this.store.getters.isStudent
        this.userId = this.store.getters.idUser
        this.ownUuid = this.store.getters.uuid
        this.publicKey = this.store.getters.publicKey

        this.type = type
        this.refList = refList
        this.state = 'constructor'

        this.eventManager.add( 'onRefreshObject-' + this.type, ( elements ) =>
        {
            this._handleAfterFetch( elements )
        } )

        this.eventManager.add( 'onRecollectCollectors-' + this.type, () =>
        {
            this._refreshCollectors()
        } )

        this.eventManager.append( 'onCrudSyncDone', () =>
        {
            if( 0 === this.elementCount )
            {
                this.fillCache()
            }
        } )

        this.eventManager.add( 'after-mass-archived-' + this.type, () =>
        {

            this.elementCount = 0
            this.fillCache()

        } )

        this.statesList = personalAttributesStatesList
        this.statesSynced = false
        this.firstCrudSyncDone = false
        this.needsStateReset = false

        this.elementCount = 0
        this.resetCounter = 0

        this.baseMeta = [ 'isDemoContent',
                          'idOwner',
                          'lasteditor',
                          'editlist',
                          'isShadowCopy',
                          'isStudentEditable',
                          'archived',
                          'archiveKey',
                          'archiveReference',
                          'remoteId',
                          'fieldHistory',
                          'remoteUpdateTimestamp',
                          'shadowedId' ]

        this.logSign = 'AbstractObjectClass::Type' + this.functions.ucFirst( type ) + '::'

        this.eventManager.append( 'on-store-ready', () =>
        {

            if( true === this.store.getters.authorized )
            {
                this.startup()
            }
            else
            {
                this.eventManager.append( 'on-login-state-change', () =>
                {
                    if( true === this.store.getters.authorized )
                    {
                        this.startup()
                    }
                } )
            }

        } )

        return this

    }

    startup()
    {
        this.setupRegistry( true )
        this.provisioning()
        this.startupStateEngine()
    }

    startupStateEngine()
    {
        this.eventManager.append( 'full-objects-cache-flush', () =>
        {
            this.setupRegistry( true )
            this.provisioning( true )
        } )

        this.eventManager.add( 'on-' + this.type + '-element-update', ( localId ) =>
        {
            this._refreshCache( localId )
        } )

        this.eventManager.append( 'after-first-crud-sync', () =>
        {
            if( !this.firstCrudSyncDone )
            {
                this.personalAttributes.prepareStateList( true )
            }
            this.firstCrudSyncDone = true
        } )

        if( true !== this.store.getters.online )
        {
            this.firstCrudSyncDone = true
            this.needsStateReset = true
        }

        this.eventManager.append( 'on-websocket-open', () =>
        {
            if( true === this.needsStateReset )
            {
                this.firstCrudSyncDone = false
            }
        } )
    }

    setupRegistry( flush )
    {

        if( flush )
        {
            delete this.registry
        }

        this.registry = reactive( {
            cacheKey          : false,
            archiveKey        : false,
            shadowCopyKey     : false,
            cache             : new Map(),
            archive           : new Map(),
            collectors        : new Map(),
            shadowCopies      : new Map(),
            byShadowedId      : new Map(),
            studentShadows    : new Map(),
            shadowedForStudent: new Map(),
            byReferenceId     : new Map(),
            byReference       : new Map(),
            sortMap           : new Map(),
            isOwn             : new Map(),
            indexMaps         : new Map(),
            hasCompetences    : []
        } )

    }

    provisioning( skipPersonalAttributes )
    {

        this.logger.clog( this.logSign + 'provisioning', 'provisioning userid & uuid...' )

        if( null !== this.store.getters.uuid )
        {

            this.userId = this.store.getters.idUser
            this.ownUuid = this.store.getters.uuid
            this.publicKey = this.store.getters.publicKey
            this.isStudent = this.store.getters.isStudent

            if( !skipPersonalAttributes )
            {
                this.personalAttributes.prepareStateList()
            }

            this.state = 'initial'

            this.eventManager.dispatchAndRemove( 'after-provisioning-' + this.type )

        }
        else
        {

            setTimeout( () =>
            {

                this.provisioning( skipPersonalAttributes )

            }, 2000 )
        }

    }

    cacheHeatup()
    {
        return new Promise( resolve =>
        {

            this.validateCache()
                .then( () =>
                {
                    switch( this.state )
                    {
                        case 'initial':
                            this.state = 'filling'
                            return resolve( this.fillCache() )
                        case 'filling':
                            this.eventManager.append( 'on-filled-state-' + this.type, () =>
                            {
                                return resolve()
                            } )
                            break
                        case 'filled':
                            this.eventManager.dispatch( 'after-cache-heatup-' + this.type )
                            return resolve()
                    }

                } )

        } )
    }

    /*eslint-disable*/
    _decryptListAll( queue )
    {

        return new Promise( resolve =>
        {

            let promises = [],
                results  = []

            for( let q in queue )
            {

                promises.push( () =>
                {

                    return new Promise( resolve =>
                    {

                        let item = queue[ q ]
                        this.cryptoHelper.decryptElement( item.localKey, item.cryptedObject )
                            .then( element =>
                            {

                                if( false !== element
                                    && null !== element
                                    && undefined !== element )
                                {

                                    let attributes = Object.entries( element )

                                    for( let [ key, value ] of attributes )
                                    {
                                        if( key !== '_keys'
                                            && key !== 'remoteUpdateTimestamp'
                                            && ( key !== 'remoteId' || item.element.remoteId === undefined ) )
                                        {
                                            item.element[ key ] = value
                                        }
                                    }

                                    results.push( item.element )

                                }

                                return resolve()

                            } )

                    } )

                } )

            }

            this.functions.promiseRunner( promises )
                .then( () =>
                {

                    promises = null
                    return resolve( results )

                } )

        } )

    }

    filter( list, filter )
    {

        let returnable = []

        for( let i = 0; i < list.length; i++ )
        {

            let row = list[ i ]
            if( undefined !== filter.override
                && row[ filter.override.key ] == filter.override.value )
            {

                returnable.push( row )

            }
            else
            {

                let filterable = row[ filter.key ]
                if( -1 < filter.key.indexOf( ',' ) )
                {

                    let keys = filter.key.split( ',' )
                    filterable = row

                    for( let k in keys )
                    {
                        filterable = filterable[ keys[ k ] ]
                    }

                }

                let match = false

                switch( filter.method )
                {
                    case 'equals':
                        match = filterable == filter.filter
                        break
                    case 'indexOf':
                        match = -1 < filterable.indexOf( filter.filter )
                        break
                    case 'in':
                        match = -1 < filter.filter.indexOf( filterable )
                        break

                }

                if( match )
                {
                    returnable.push( row )
                }

            }

        }

        return returnable

    }

    getCacheCount()
    {
        return this.registry.cache.size
    }

    _setElementStates( item )
    {

        if( undefined !== item )
        {

            for( let s in this.statesList )
            {

                let state     = this.statesList[ s ].state,
                    list      = this.statesList[ s ].list,
                    stateList = this.personalAttributes.registry[ list ].get( item.type )

                if( false !== item
                    && undefined !== stateList
                    && ( -1 < stateList.indexOf( item.localId )
                         || -1 < stateList.indexOf( item.referenceKey ) ) )
                {
                    item[ state ] = true
                }
                else
                {
                    item[ state ] = false
                }

            }

        }

    }

    updateShadowCopy( identifier, copy )
    {
        this.registry.shadowCopies.set( identifier, copy )
        this.registry.shadowCopyKey = this.functions.mapHash( this.registry[ 'shadowCopies' ] )
    }

    deleteShadowCopy( identifier )
    {
        this.registry.shadowCopies.delete( identifier )
        this.registry.shadowCopyKey = this.functions.mapHash( this.registry[ 'shadowCopies' ] )
    }

    _baseHash()
    {

        this.registry.cacheKey = this.functions.mapHash( this.registry[ 'cache' ] )
        this.registry.archiveKey = this.functions.mapHash( this.registry[ 'archive' ] )
        this.registry.shadowCopyKey = this.functions.mapHash( this.registry[ 'shadowCopies' ] )

        if( 'filling' === this.state )
        {
            this.state = 'filled'
            this.eventManager.dispatchAndRemove( 'on-filled-state-' + this.type )
        }

        this.eventManager.dispatch( 'on-refresh-cache-' + this.type )

    }

    generateSortMap()
    {

        let sortDirections = this.settings.getSetting( 'sortingDirections' ),
            scopes         = [ 'cache', 'archive' ]

        this.iter++

        if( undefined !== sortDirections
            && undefined !== sortDirections[ this.objectType ] )
        {

            if( 'lists' === this.objectType )
            {
                for( let s in scopes )
                {
                    this.registry.sortMap.set( scopes[ s ], this.sorter.getSortMap( this.registry[ scopes[ s ] ], sortDirections[ this.objectType ], true, true ) )
                }
                for( let s in scopes )
                {
                    this.registry.sortMap.set( 'office_' + scopes[ s ], this.sorter.getSortMap( this.registry[ scopes[ s ] ], sortDirections[ 'office' ], true, true ) )
                }
            }
            else
            {
                for( let s in scopes )
                {
                    this.registry.sortMap.set( scopes[ s ], this.sorter.getSortMap( this.registry[ scopes[ s ] ], sortDirections[ this.objectType ], false, true ) )
                }
            }
        }

        this.eventManager.dispatch( 'after-sortmap-ready' )

    }

    createFullTextHint( element )
    {
        let attributes = [
                'body',
                't:listType:list-type-',
                'listname',
                'groupname',
                'yeargroupname',
                'classname',
                'teamname',
                'label',
                'firstname',
                'lastname',
                'title'
            ],
            hint       = ''

        for( let a in attributes )
        {
            if( -1 < attributes[ a ].indexOf( 't:' ) )
            {

                let key         = attributes[ a ].split( /:/g ),
                    attribute   = key[ 1 ],
                    lookup      = key[ 2 ],
                    translation = ''

                if( undefined !== element[ attribute ] )
                {
                    translation = ' ' + this.t( lookup + element[ attribute ] )
                }

                hint += translation

            }
            else
            {
                if( undefined !== element[ attributes[ a ] ] )
                {
                    hint += ' ' + element[ attributes[ a ] ]
                }
            }
        }

        return hint

    }

    _prepareCompetenceMap( element )
    {
        if( Array.isArray( element.lists ) )
        {
            for( let l in element.lists )
            {
                if( Array.isArray( element.lists[ l ].columns ) )
                {
                    for( let c in element.lists[ l ].columns )
                    {
                        if( 'competenceSelector' === element.lists[ l ].columns[ c ].type
                            && -1 === this.registry.hasCompetences.indexOf( element.lists[ l ].referenceKey ) )
                        {
                            this.registry.hasCompetences.push( element.lists[ l ].referenceKey )
                        }
                    }
                }
            }
        }
    }

    _prepareIndexMaps( element )
    {

        this.registry.isOwn.set( element.localId, this._isOwn( element ) )

        this._prepareCompetenceMap( element )

        this.registry.indexMaps.set( element.localId, {
            color             : element.color,
            classId           : element.classId,
            classname         : element.classname,
            gender            : element.gender,
            studentReference  : element.studentReference,
            classReference    : element.classReference,
            groupReference    : element.groupReference,
            yeargroupReference: element.yeargroupReference,
            competenceCategory: element.idCompetenceCategory,
            labels            : element.labels,
            listType          : element.listType,
            archiveKey        : element.archiveKey,
            students          : element.students,
            fullTextHint      : this.createFullTextHint( element ),
            filterBy          : element.columns ? element.columns[ 0 ].filterBy : undefined
        } )

        if( element.referenceKey )
        {
            this.registry.isOwn.set( element.referenceKey, this._isOwn( element ) )
            this.registry.indexMaps.set( element.referenceKey, {
                color             : element.color,
                classId           : element.classId,
                classname         : element.classname,
                gender            : element.gender,
                studentReference  : element.studentReference,
                classReference    : element.classReference,
                groupReference    : element.groupReference,
                yeargroupReference: element.yeargroupReference,
                competenceCategory: element.idCompetenceCategory,
                labels            : element.labels,
                listType          : element.listType,
                archiveKey        : element.archiveKey,
                students          : element.students,
                fullTextHint      : this.createFullTextHint( element ),
                filterBy          : element.columns ? element.columns[ 0 ].filterBy : undefined
            } )
        }

    }

    _isOwn( element )
    {
        return ( undefined === element.idOwner
                 || parseInt( element.idOwner ) === parseInt( this.store.getters.idUser )
                 || 184 === parseInt( this.store.getters.idUser ) )
    }

    _runCollectors( force )
    {

        let toMap = []

        for( const [ localId, list ] of this.registry.collectors )
        {
            if( !list.collected || force )
            {
                this.collector.collect( localId, list, this.registry.cache, this.registry.archive )
            }
            toMap.push( localId )
        }

        return toMap

    }

    _prepareCache( list, shadowCopies, delayedHash )
    {

        if( true === this.getState( 'listEditorOpen' )
            && !this.ignoreListEditorState )
        {
            return
        }

        for( let l in list )
        {

            this._setElementStates( list[ l ] )

            if( undefined !== list[ l ].lists )
            {
                for( let ll in list[ l ].lists )
                {
                    this.functions.objectHash( list[ l ].lists[ ll ] )
                }
            }

            if( this.stateMergers.isCollector( this.type, list[ l ].listType ) )
            {
                this.registry.collectors.set( list[ l ].localId, list[ l ] )
            }
            else
            {

                let identifier = undefined !== this.cacheKey ? list[ l ][ this.cacheKey ] : list[ l ].localId
                if( true === shadowCopies )
                {
                    this.registry.byShadowedId.set( list[ l ].shadowedId, identifier )
                    this.registry.shadowCopies.set( identifier, list[ l ] )
                }
                else
                {

                    if( true === list[ l ].isShadowCopy
                        && 1 !== this.isStudent )
                    {
                        this.registry.shadowCopies.set( identifier, list[ l ] )
                    }
                    else
                    {

                        switch( list[ l ].archived )
                        {
                            case true:
                                if( false === this.settings.getSetting( 'archiveCachingDisabled' ) )
                                {
                                    this.registry.archive.set( identifier, list[ l ] )
                                    if( undefined !== list[ l ].idReference )
                                    {
                                        this.registry.byReferenceId.set( list[ l ].idReference, list[ l ].localId )
                                    }
                                }
                                break
                            default:
                                this.registry.cache.set( identifier, list[ l ] )
                                if( undefined !== list[ l ].idReference )
                                {
                                    this.registry.byReferenceId.set( list[ l ].idReference, list[ l ].localId )
                                }
                                break
                        }
                    }
                }

                if( 1 === this.isStudent )
                {
                    this.registry.studentShadows.set( list[ l ].shadowedId, identifier )
                    this.registry.shadowedForStudent.set( identifier, list[ l ].shadowedId )
                }

                this.functions.objectHash( list[ l ] )
                this._prepareIndexMaps( list[ l ] )

            }

        }

        this._checkCollectors()
        this.generateSortMap()

        if( !shadowCopies )
        {
            let timeout = delayedHash ? 800 : 50
            setTimeout( () =>
            {
                this._baseHash()
            }, timeout )
        }

    }

    _checkCollectors( force )
    {
        if( 0 < this.registry.collectors.size )
        {
            let toMap = this._runCollectors( force )
            for( let t in toMap )
            {
                let list = this.registry.cache.get( toMap[ t ] )
                if( undefined === list )
                {
                    list = this.registry.archive.get( toMap[ t ] )
                }
                if( undefined !== list )
                {
                    this.functions.objectHash( list )
                    this.eventManager.dispatch( 'on-recollect-' + list.localId )
                    this._prepareIndexMaps( list )
                }
            }
        }
    }

    _refreshCollectors()
    {
        this._checkCollectors( true )
        this.generateSortMap()
        this._baseHash()
    }

    _refreshCache( localId, element )
    {

        if( undefined === element )
        {
            this.loadItem( localId )
                .then( element =>
                {

                    this._refreshCache( localId, element )

                } )
                .catch( () =>
                {
                    return
                } )
        }
        else
        {
            this._setElementStates( element )
            this._handleAfterUpdate( element )
                .finally( () =>
                {
                    this._prepareIndexMaps( element )
                    this.generateSortMap()
                } )
        }

    }

    refreshCache( localId, element )
    {
        this._refreshCache( localId, element )
    }


    _refreshCachePromise( localId, element )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( undefined === element )
            {
                this.loadItem( localId )
                    .then( element =>
                    {

                        return resolve( this._refreshCachePromise( localId, element ) )

                    } )
                    .catch( () =>
                    {
                        return reject()
                    } )
            }
            else
            {
                this._setElementStates( element )
                this._handleAfterUpdate( element )
                    .finally( () =>
                    {
                        this._prepareIndexMaps( element )
                        this.generateSortMap()
                        return resolve()
                    } )
            }

        } )

    }

    refreshCachePromise( localId, element )
    {
        return new Promise( ( resolve, reject ) =>
        {
            this._refreshCachePromise( localId, element )
                .then( () =>
                {
                    return resolve()
                } )
                .catch( () =>
                {
                    return reject()
                } )
        } )
    }

    _removeFromCache( localId )
    {

        let cacheMap = this.registry.sortMap.get( 'cache' )

        if( undefined !== cacheMap )
        {
            this.functions.removeFromArray( cacheMap, localId )
        }

        this.registry.cache.delete( localId )
        this.registry.cacheKey = this.functions.mapHash( this.registry[ 'cache' ] )

    }

    _removeFromArchive( localId )
    {

        let archiveMap = this.registry.sortMap.get( 'archive' )

        if( undefined !== archiveMap )
        {
            this.functions.removeFromArray( archiveMap, localId )
        }

        this.registry.archive.delete( localId )
        this.registry.archiveKey = this.functions.mapHash( this.registry[ 'archive' ] )

    }

    hasObject( localId )
    {

        let scopes = [ 'cache', 'archive' ]

        for( let s in scopes )
        {
            if( this.registry[ scopes[ s ] ].has( localId ) )
            {
                return scopes[ s ]
            }
        }

        return null

    }

    hasValuesFor( localId, referenceId )
    {

        let scope = this.hasObject( localId )
        if( null === scope )
        {
            return false
        }

        let item = this.registry[ scope ].get( localId )
        if( undefined === item || undefined === item.values )
        {
            return false
        }

        for( let v in item.values )
        {
            if( -1 < v.indexOf( referenceId ) )
            {
                return true
            }
        }
        return false

    }

    getById( localId, recurse )
    {

        let scopes = [ 'cache', 'archive' ]

        for( let s in scopes )
        {
            if( this.registry[ scopes[ s ] ].has( localId ) )
            {
                return this.registry[ scopes[ s ] ].get( localId )
            }
        }

        if( 1 === this.isStudent
            && undefined === recurse )
        {
            return this.getStudentVersionForId( localId )
        }

        return undefined

    }

    getStudentVersionForId( localId )
    {
        let shadowedId = this.registry.studentShadows.get( localId )
        if( undefined !== shadowedId )
        {
            let element = this.getById( shadowedId, true )
            return element
        }
    }

    getShadowCopyById( localId )
    {
        return this.registry.shadowCopies.get( localId )
    }

    getLocalIdByShadowedId( shadowId )
    {
        return this.registry.byShadowedId.get( shadowId )
    }

    list( scope )
    {
        return Array.from( this.registry[ scope ].values() )
    }

    _mergeCache()
    {
        return [ ...this.list( 'archive' ), ...this.list( 'cache' ) ]
    }

    readCache( scope )
    {
        try
        {
            throw new Error( 'fucked up' )
        }
        catch( e )
        {
            console.log( 'DBG', 'i am still using the old cache-methods:', e )
        }
        switch( scope )
        {
            case 'cache':
            case 'archive':
                return this.list( scope )
            default:
                return this._mergeCache()
        }
    }

    getCache( scope )
    {
        switch( scope )
        {
            case 'cache':
            case 'archive':
                return this.registry[ scope ]
            default:
                return {
                    cache  : this.registry.cache,
                    archive: this.registry.archive
                }
        }
    }

    getPreparedCache( scope )
    {
        return new Promise( resolve =>
        {

            this.cacheHeatup()
                .then( () =>
                {

                    return resolve( this.getCache( scope ) )

                } )

        } )
    }

    _handleAfterFetch( items, shadowCopies )
    {

        return new Promise( resolve =>
        {

            let merger = this.stateMergers.get( this.type, 'afterFetch' ),
                resort = this.stateMergers.get( this.type, 'resortCache' )

            if( !Array.isArray( items ) )
            {
                items = [ items ]
            }
            if( false !== merger )
            {
                merger.afterFetch( items, false )
                      .then( list =>
                      {
                          if( false !== resort )
                          {
                              this.sorter.multiSortObjects( list, resort )
                          }

                          this._prepareCache( list, shadowCopies )
                          return resolve( list )
                      } )
            }
            else
            {
                if( false !== resort )
                {
                    this.sorter.multiSortObjects( items, resort )
                }

                this._prepareCache( items, shadowCopies )
                return resolve( items )
            }

        } )
    }

    _handleAfterUpdate( items, delayedHash )
    {

        return new Promise( resolve =>
        {

            let merger = this.stateMergers.get( this.type, 'afterUpdate' ),
                append = this.stateMergers.get( this.type, 'append' ),
                resort = this.stateMergers.get( this.type, 'resortCache' )

            if( !Array.isArray( items ) )
            {
                items = [ items ]
            }

            if( undefined !== items[ 0 ] )
            {

                let scope = true === items[ 0 ].archived ? 'archive' : 'cache'
                if( true === items[ 0 ].isShadowCopy
                    && 1 !== this.isStudent )
                {
                    scope = 'shadowCopies'
                }

                if( false !== merger )
                {

                    if( false !== resort )
                    {
                        this.sorter.multiSortObjects( items, resort )
                    }

                    merger.afterUpdate( items, this.registry[ scope ], false )
                          .then( list =>
                          {
                              this._prepareCache( list, false, delayedHash )
                              if( undefined !== append
                                  && undefined !== append.afterUpdate )
                              {
                                  this.eventManager.dispatch( 'on-after-update-merge-' + append.afterUpdate, delayedHash )
                              }
                              return resolve( list )
                          } )
                          .catch( () =>
                          {
                              this._prepareCache( items, false, delayedHash )
                              return resolve( items )
                          } )
                }
                else
                {

                    if( false !== resort )
                    {
                        this.sorter.multiSortObjects( items, resort )
                    }

                    this._prepareCache( items, false, delayedHash )
                    return resolve( items )

                }

            }

            return resolve( items )

        } )
    }

    _handleAfterUpdateTriggered( delayedHash, scope )
    {
        scope = scope || 'cache'
        let list = []
        for( const [ key, value ] of this.registry[ scope ] )
        {
            list.push( value )
        }
        this._handleAfterUpdate( list, delayedHash )
    }

    validateCache()
    {
        return new Promise( resolve =>
        {

            if( 'filled' === this.state
                && ( this.registry.cache.size === 0 && this.registry.archive.size === 0 )
                && ( !this.firstCrudSyncDone || true === this.getState( 'sync-had-' + this.type ) )
                && 3 < this.resetCounter )
            {

                this.resetCounter++
                this.logger.clog( this.logSign, '0 elements found in filled cache: resetting' )
                this.state = 'initial'
                return resolve()

            }
            else
            {
                return resolve()
            }

        } )

    }

    _handleShadowCopies( copies )
    {

        return new Promise( resolve =>
        {

            this._handleAfterFetch( copies, true )
                .then( () =>
                {
                    return resolve()
                } )

        } )

    }

    fillCache()
    {
        return new Promise( resolve =>
        {

            this.database
                .readAllObjectsByType( this.type )
                .then( result =>
                {

                    let decryptQueue = []

                    for( let i = 0; i < result.length; i++ )
                    {

                        this.elementCount++
                        let list = result[ i ].object

                        if( undefined !== list )
                        {

                            let localId               = result[ i ].id,
                                remoteId              = result[ i ].object.remoteId,
                                remoteUpdateTimestamp = result[ i ].object.remoteUpdateTimestamp,
                                idOwner               = result[ i ].object.idOwner || this.userId,
                                editlist              = result[ i ].object.editlist || [],
                                archived              = result[ i ].object.archived || false,
                                archiveKey            = result[ i ].object.archiveKey || null,
                                lasteditor            = result[ i ].object.lasteditor || this.userId,
                                isShadowCopy          = result[ i ].object.isShadowCopy || undefined,
                                ownDecrypted          = false

                            if( null !== archiveKey
                                && undefined !== archiveKey
                                && true === this.settings.getSetting( 'archiveCachingDisabled' ) )
                            {
                                continue
                            }

                            for( let k in list.keys )
                            {

                                let key = list.keys[ k ]

                                if( key.uuid === this.ownUuid
                                    && !ownDecrypted )
                                {

                                    let localKey = key.key
                                    ownDecrypted = true

                                    decryptQueue.push( {
                                        element      : {
                                            localId              : localId,
                                            remoteId             : remoteId,
                                            localKey             : localKey,
                                            idOwner              : idOwner,
                                            editlist             : editlist,
                                            archived             : archived,
                                            archiveKey           : archiveKey,
                                            lasteditor           : lasteditor,
                                            remoteUpdateTimestamp: remoteUpdateTimestamp,
                                            _keys                : list.keys,
                                            type                 : this.type
                                        },
                                        localKey     : localKey,
                                        cryptedObject: list.object
                                    } )

                                }

                            }

                        }

                    }


                    this._decryptListAll( decryptQueue )
                        .then( listItems =>
                        {

                            let elements     = [],
                                shadowCopies = []

                            for( let l in listItems )
                            {

                                if( true === this.settings.getSetting( 'archiveCachingDisabled' )
                                    && listItems[ l ].archived === true )
                                {
                                    continue
                                }

                                if( true === listItems[ l ].isShadowCopy
                                    && 1 !== this.isStudent )
                                {
                                    shadowCopies.push( listItems[ l ] )
                                }
                                else
                                {
                                    elements.push( listItems[ l ] )
                                }

                            }

                            this._handleShadowCopies( shadowCopies )
                                .then( () =>
                                {

                                    return resolve( this._handleAfterFetch( elements ) )

                                } )

                        } )

                } )

        } )

    }

    _listAll()
    {

        return new Promise( resolve =>
        {

            this.validateCache()
                .then( () =>
                {

                    switch( this.state )
                    {
                        case 'initial':
                            this.state = 'filling'
                            return resolve( this.fillCache() )
                            break
                        case 'filling':
                            this.eventManager.append( 'on-filled-state-' + this.type, () =>
                            {
                                return resolve( this._listAll() )
                            } )
                            break
                        case 'filled':
                            return resolve( this._mergeCache() )

                    }

                } )

        } )

    }

    listAll( attach, filter, skipArchived, skipWait )
    {

        try
        {
            throw new Error( 'foo' )
        }
        catch( e )
        {
            console.log( '>>>>>>>>>>>>> LISTALL', this.type, e )
        }

        attach = attach || undefined
        filter = filter || undefined
        skipArchived = skipArchived || undefined
        skipWait = skipWait || undefined

        return new Promise( ( resolve, reject ) =>
        {

            let list = []

            this._listAll()
                .then( result =>
                {

                    if( this.firstCrudSyncDone
                        || 0 < this.elementCount
                        || skipWait )
                    {

                        for( let r in result )
                        {

                            let item = result[ r ]
                            if( ( true !== skipArchived && item.archived === true )
                                || item.archived !== true )
                            {
                                list.push( item )
                            }
                        }

                        this.resolver.run( attach, list )
                            .then( resolveResult =>
                            {

                                let returnable = resolveResult

                                if( undefined !== filter )
                                {

                                    returnable = this.filter( resolveResult, filter )

                                }

                                return resolve( returnable )

                            } )
                            .catch( () =>
                            {
                                return reject( 'resolver error' )
                            } )

                    }

                    if( 0 === this.elementCount
                        && !this.firstCrudSyncDone )
                    {
                        this.eventManager.append( 'after-first-crud-sync', () =>
                        {
                            this.firstCrudSyncDone = true
                            return resolve( this.listAll( attach, filter, skipArchived ) )
                        } )

                    }

                } )

        } )

    }

    listAllNoWait()
    {
        return new Promise( ( resolve, reject ) =>
        {

            let list = []

            this._listAll()
                .then( result =>
                {

                    for( let r in result )
                    {

                        let item = result[ r ]
                        list.push( item )
                    }

                    this.resolver.run( undefined, list )
                        .then( resolveResult =>
                        {

                            let returnable = resolveResult
                            return resolve( returnable )

                        } )
                        .catch( e =>
                        {
                            return reject( 'resolver error', e )
                        } )

                } )

        } )

    }

    _prepareBaseAttributes( result )
    {

        return {
            localId              : result.id,
            remoteId             : result.object.remoteId,
            remoteUpdateTimestamp: result.object.remoteUpdateTimestamp,
            idOwner              : result.object.idOwner || this.userId,
            editlist             : result.object.editlist || [],
            archived             : result.object.archived || false,
            archiveKey           : result.object.archiveKey || null,
            lasteditor           : result.object.lasteditor || this.userId,
            localKey             : this.cryptoHelper.getOwnElementKey( result.object ),
            isShadowCopy         : result.object.isShadowCopy || undefined,
            type                 : this.type,
            _keys                : result.object.keys
        }

    }

    loadItem( localId )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.database.readObject( localId )
                .then( result =>
                {

                    let baseAttributes = this._prepareBaseAttributes( result )
                    this.cryptoHelper.decrypt( result.object )
                        .then( decrypted =>
                        {

                            if( decrypted !== false )
                            {

                                for( let b in baseAttributes )
                                {
                                    if( undefined === decrypted[ b ]
                                        && undefined !== baseAttributes[ b ] )
                                    {
                                        decrypted[ b ] = baseAttributes[ b ]
                                    }
                                }

                                this._setElementStates( decrypted )
                                return resolve( decrypted )

                            }
                            else
                            {
                                return reject( 'ERROR_DECRYPT' )
                            }

                        } )

                } )
        } )
    }

    load( localId, full )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.database.readObject( localId )
                .then( result =>
                {

                    this.cryptoHelper.decrypt( result.object )
                        .then( decrypted =>
                        {

                            if( decrypted !== false )
                            {
                                decrypted.type = decrypted.type || this.type
                                if( full !== undefined )
                                {

                                    return resolve(
                                        {
                                            localId              : localId,
                                            remoteId             : undefined !== result.object.remoteId ? result.object.remoteId : decrypted.remoteId,
                                            idOwner              : undefined !== result.object.idOwner ? result.object.idOwner : ( undefined !== decrypted.idOwner ? decrypted.idOwner : this.userId ),
                                            object               : decrypted,
                                            raw                  : result.object,
                                            editlist             : undefined !== result.object.editlist ? result.object.editlist : ( undefined !== decrypted.editlist ? decrypted.editlist : [] ),
                                            archived             : undefined !== result.object.archived ? result.object.archived : ( undefined !== decrypted.archived ? decrypted.archived : false ),
                                            archiveKey           : undefined !== result.object.archiveKey ? result.object.archiveKey : ( undefined !== decrypted.archiveKey ? decrypted.archiveKey : null ),
                                            lasteditor           : undefined !== result.object.lasteditor ? result.object.lasteditor : ( undefined !== decrypted.lasteditor ? decrypted.lasteditor : this.store.getters.idUser ),
                                            remoteUpdateTimestamp: result.object.remoteUpdateTimestamp,
                                            type                 : this.type,
                                            _keys                : result.object.keys,
                                        }
                                    )
                                }
                                else
                                {

                                    return resolve( decrypted )

                                }
                            }
                            else
                            {
                                return reject( 'ERROR_STORABLE_DECRYPT' )
                            }

                        } )

                } )
                .catch( error =>
                {
                    return reject( error )
                } )

        } )

    }

    getBaseMeta( values, localId, fromSync )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.load( localId, true )
                .then( result =>
                {

                    values.idOwner = result.idOwner
                    values.editlist = result.editlist
                    values.archived = undefined === values.archived ? result.archived : values.archived
                    values.archiveKey = undefined === values.archiveKey ? result.archiveKey : values.archiveKey
                    values.fieldHistory = undefined === values.fieldHistory ? result.fieldHistory : values.fieldHistory
                    values.remoteUpdateTimestamp = undefined === values.remoteUpdateTimestamp ? result.remoteUpdateTimestamp : values.remoteUpdateTimestamp

                    if( fromSync === undefined )
                    {
                        values.lasteditor = this.userId
                    }
                    else
                    {
                        values.lasteditor = result.lasteditor
                    }

                    return resolve( values )

                } )
                .catch( error =>
                {
                    this.logger.cerror( this.logSign + 'getBaseMeta', 'failed fetching meta for', localId, ( fromSync ? 'from sync' : '' ) )
                    return reject( error )
                } )

        } )
    }

    _getKeys( job, additionalKeys, localId )
    {

        return new Promise( resolve =>
        {

            localId = localId || job.id

            let keys = {
                localKey      : undefined,
                additionalKeys: []
            }

            this.database.readObject( localId )
                .then( dbObject =>
                {

                    for( let k in dbObject.object.keys )
                    {
                        let uuid = dbObject.object.keys[ k ].uuid
                        if( uuid !== this.ownUuid )
                        {
                            keys.additionalKeys.push( uuid )
                        }
                        else
                        {
                            keys.localKey = dbObject.object.keys[ k ].key
                        }
                    }

                    return resolve( keys )

                } )
                .catch( () =>
                {
                    if( Array.isArray( additionalKeys )
                        && 0 < additionalKeys.length )
                    {
                        keys.additionalKeys = additionalKeys
                        return resolve( keys )
                    }
                    else
                    {
                        return resolve( false )
                    }
                } )

        } )

    }

    _createJson( remoteId, values, additional, timestamp, noUpdateTimestamp )
    {

        let jsonObject       = {},
            historicStudents = null

        for( let i = 0; i < this.refList.length; i++ )
        {

            let refName = this.refList[ i ]
            switch( refName )
            {
                case 'timestamp':
                    jsonObject.timestamp = ( timestamp === undefined ? Date.now() : timestamp )
                    break
                case 'update':
                    if( true !== noUpdateTimestamp )
                    {
                        jsonObject.update = ( timestamp !== undefined ? Date.now() : undefined )
                    }
                    else
                    {
                        jsonObject.update = values.update
                    }
                    break
                case 'historicStudents':
                    if( undefined !== values.historicStudents
                        && null !== values.historicStudents )
                    {
                        historicStudents = values.historicStudents.split( /,/g )
                    }
                    break
                case 'forceTimestamp':
                case 'remoteUpdateTimestamp':
                    break
                default:
                    jsonObject[ refName ] = values[ refName ]
                    break
            }

        }

        for( let m in this.baseMeta )
        {
            if( undefined !== values[ this.baseMeta[ m ] ] )
            {
                jsonObject[ this.baseMeta[ m ] ] = values[ this.baseMeta[ m ] ]
            }
        }

        if( undefined === jsonObject.isShadowCopy )
        {
            jsonObject.isShadowCopy = false
        }

        if( undefined !== additional )
        {

            let add = Object.entries( additional )
            for( let [ key, value ] of add )
            {

                jsonObject[ key ] = value

            }

        }

        if( undefined !== remoteId )
        {
            jsonObject.remoteId = remoteId
        }

        if( null !== historicStudents
            && 0 < historicStudents.length )
        {
            for( let h in historicStudents )
            {
                let id = historicStudents[ h ]
                if( -1 === jsonObject.students.indexOf( id ) )
                {
                    jsonObject.students.push( id )
                }
            }
        }

        return jsonObject

    }

    prepareForStorage( job, additionalKeys, localId, remoteId )
    {

        return new Promise( resolve =>
        {

            this._getKeys( job, additionalKeys, localId )
                .then( keys =>
                {
                    if( false === keys )
                    {
                        keys = {
                            localKey      : undefined,
                            additionalKeys: []
                        }
                    }

                    this.cryptoHelper.encryptObject(
                            this.publicKey,
                            JSON.stringify( job ),
                            keys.localKey,
                            undefined,
                            keys.additionalKeys )
                        .then( encryptedObject =>
                        {

                            if( false === encryptedObject )
                            {
                                return resolve( false )
                            }

                            if( undefined !== remoteId )
                            {
                                encryptedObject.remoteId = remoteId
                            }

                            let returnedId   = ( undefined === localId ? job.id : localId ),
                                referenceKey = '' + job.referenceKey

                            return resolve( {
                                localId     : returnedId,
                                crypted     : encryptedObject,
                                referenceKey: referenceKey
                            } )

                        } )

                } )

        } )

    }

    _writeUpload( localId, remoteId )
    {

        this.database.writeUploadsList( [ {
                type   : this.type,
                localId: localId
            } ] )
            .then( () =>
            {
                this.eventManager.dispatchAndRemove( 'on-upload-written-' + localId )
                this.logger.clog( this.logSign + '_writeUpload', this.type, 'element', localId, 'remote', ( remoteId ? remoteId : 'new' ), 'queued to upload list' )
            } )

    }

    processUpdate( complete, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp )
    {

        let job = this._createJson( remoteId, complete, additionalKeys, timestamp, noUpdateTimestamp )

        this.prepareForStorage( job, additionalKeys, localId, remoteId )
            .then( prepared =>
            {

                this.database.writeObject( prepared.crypted, prepared.localId, job.objectType )
                    .then( () =>
                    {

                        job = null

                        this.logger.clog( this.logSign + 'processUpdate', this.type, 'element', prepared.localId, 'updated' )

                        this.loadItem( prepared.localId )
                            .then( element =>
                            {

                                this.personalAttributes
                                    .prepareStateList( true )
                                    .then( () =>
                                    {

                                        this._handleAfterUpdate( element )
                                            .then( () =>
                                            {

                                                this.eventManager.dispatchIndexed( 'on-storable-update-' + this.type )
                                                this.eventManager.dispatchAndRemove( 'storable-after-update-' + prepared.localId, prepared.localId )
                                                this.eventManager.dispatchAndRemove( 'on-queue-done-' + prepared.localId )

                                            } )

                                    } )

                            } )

                        this._writeUpload( localId )

                    } )

            } )

    }

    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        this.logger.clog( this.logSign + 'create', 'creating new object of type ' + this.type )

        let merger = this.stateMergers.get( this.type, 'beforeCreate' )
        if( undefined !== merger
            && undefined !== merger.beforeCreate )
        {
            merger.beforeCreate( values )
        }

        jobId = undefined === jobId ? this.uuid.generate() : jobId

        if( clone )
        {
            this.eventManager.append( 'storable-after-update-' + jobId, () =>
            {
                this.eventManager.dispatch( 'on-shadowcopies-full-resync' )
            } )
        }

        if( undefined !== values.forceTimestamp
            && '' !== ( '' + values.forceTimestamp ).trim()
            && 'create' !== values.forceTimestamp )
        {
            forceTimestamp = values.forceTimestamp
        }

        if( undefined !== values.referenceKey
            && 'create' === values.referenceKey )
        {
            values.referenceKey = jobId
        }

        let jsonObject = this._createJson( undefined, values, additional )

        if( ( clone && undefined !== forceTimestamp && null !== forceTimestamp )
            || ( undefined !== forceTimestamp && null !== forceTimestamp ) )
        {
            this.logger.clog( this.logSign + 'create', 'forcing timestamp for cloned object: ' + forceTimestamp )
            jsonObject.timestamp = forceTimestamp
            jsonObject.timestampForced = true
        }

        this.prepareForStorage( jsonObject, additionalKeys, jobId )
            .then( prepared =>
            {

                let localId = prepared.localId

                this.database.writeObject( prepared.crypted, localId, this.type )
                    .then( () =>
                    {

                        this.logger.clog( this.logSign + 'create', this.type, 'element', localId, 'created' )
                        this.loadItem( localId )
                            .then( element =>
                            {

                                this._handleAfterUpdate( element )
                                    .then( () =>
                                    {

                                        this.eventManager.dispatchAndRemove( 'storable-after-update-' + localId, localId )
                                        this.eventManager.dispatchAndRemove( 'on-queue-done-' + localId )

                                        this.eventManager.dispatchIndexed( 'on-storable-create-' + this.type, localId )

                                        this.eventManager.dispatch( 'set-bus-trigger', [ 'lastObjectType', this.type ] )
                                        this.eventManager.dispatch( 'set-bus-trigger', [ 'lastObjectCreated', localId ] )

                                        if( !local )
                                        {
                                            this._writeUpload( localId )
                                        }

                                        prepared = null
                                        jsonObject = null
                                        additionalKeys = null
                                        values = null

                                    } )
                            } )
                    } )

            } )

        return jobId

    }

    createWithPromise( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        return new Promise( resolve =>
        {

            let localId = this.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
            this.eventManager.append( 'on-queue-done-' + localId, () =>
            {
                return resolve()
            } )

        } )

    }

    _listAdditionalKeys( keys )
    {

        let list = []
        for( let k in keys )
        {
            if( keys[ k ].uuid !== this.ownUuid )
            {
                list.push( keys[ k ].uuid )
            }
        }

        return list

    }

    clone( localId, forceTimestamp )
    {

        let jobId = this.uuid.generate()

        this.statistics.count( 'cloned-a-list' )
        this.logger.clog( this.logSign + 'clone', 'processing ' + this.type + ' #' + localId )

        this.database.readObject( localId )
            .then( cryptedOriginal =>
            {

                let additionalKeys = this._listAdditionalKeys( cryptedOriginal.object.keys )
                this.cryptoHelper.decrypt( cryptedOriginal.object )
                    .then( original =>
                    {

                        if( false !== original )
                        {
                            this.getBaseMeta( original, localId )
                                .then( element =>
                                {

                                    let copy          = {},
                                        nonCloneables = [ 'values',
                                                          'fieldHistory',
                                                          'lastEditor',
                                                          'remoteId',
                                                          'remoteUpdateTimestamp',
                                                          'revision',
                                                          'isDemoContent',
                                                          'timestamp',
                                                          'update' ]

                                    for( let [ key, value ] of Object.entries( element ) )
                                    {
                                        if( -1 === nonCloneables.indexOf( key ) )
                                        {
                                            copy[ key ] = value
                                        }
                                    }
                                    if( undefined !== forceTimestamp )
                                    {
                                        this.logger.clog( this.logSign + 'clone', 'forcing timestamp to be ' + forceTimestamp )
                                    }

                                    this.create( copy, undefined, true, forceTimestamp, additionalKeys, jobId )

                                    nonCloneables = null
                                    cryptedOriginal = null

                                } )
                                .catch( error =>
                                {
                                    this.logger.cerror( this.logSign + 'clone', 'failed for #' + localId + ' with error:' + error )
                                } )

                        }
                        else
                        {
                            this.logger.cerror( this.logSign + 'clone', 'failed for #' + localId + ' decryption failed.' )
                        }

                    } )

            } )

        return jobId

    }


    update( values, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
    {
        if( undefined === values )
        {
            return undefined
        }

        this.eventManager.dispatch( 'block-sync-for-update', localId )

        localId = localId || values.localId
        remoteId = remoteId || values.remoteId
        timestamp = timestamp || values.timestamp
        localKey = localKey || values.localKey

        //let values = JSON.parse( JSON.stringify( rawObject ) )
        if( false === values )
        {
            return undefined
        }

        let revision = values.revision !== undefined ? ( parseInt( values.revision ) + 1 ) : 0
        values.revision = revision

        this.eventManager.append( 'storable-after-update-' + localId, ( localId ) =>
        {
            this.eventManager.dispatchAndRemove( 'after-updated-' + localId, localId )
            this.eventManager.dispatch( 'storable-after-updated-' + localId )
            this.eventManager.dispatchAndRemove( 'on-storable-updated-' + localId )
        } )

        if( undefined !== localId
            && ( undefined === values.lasteditor
                 || undefined === values.editlist
                 || undefined === values.idOwner
                 || undefined === values.archived
                 || undefined === values.archiveKey
                 || undefined === values.archiveReference
                 || undefined === values.fieldHistory ) )
        {

            this.getBaseMeta( values, localId, fromSync )
                .then( complete =>
                {

                    this.processUpdate( complete, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp )

                } )
                .catch( error =>
                {

                    this.logger.cerror( this.logSign + 'update', 'rejected base meta call', error )

                } )

        }
        else
        {
            if( undefined === localId )
            {
                this.logger.cerror( this.logSign + 'update', 'undefined localId: ' + JSON.stringify( values ) + ' | remote: ' + remoteId )
            }
            else
            {
                this.processUpdate( values, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp )
            }
        }

        return localId

    }

    nonBlockingUpdate( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
    {
        return new Promise( resolve =>
        {

            let jobId = this.update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
            return resolve( jobId )

        } )
    }

    delete( localId, remoteId, forceRemote )
    {

        return new Promise( resolve =>
        {

            this.eventManager.dispatch( 'block-sync-for-deletion', localId )
            this.database.deleteObject( localId )
                .then( () =>
                {
                } )
                .catch( error =>
                {
                    this.logger.cdebug( this.logSign + 'delete', 'deletion of #' + localId, 'returned error', error, 'this might be the result of an unfinished, earlier deletion job' )
                } )
                .finally( () =>
                {

                    this.queueWorker.enqueue( 'delete', localId, this.type, undefined, undefined, undefined, true )

                    let indexMap    = this.registry.indexMaps.get( localId ),
                        hasStudents = undefined !== indexMap ? Array.isArray( indexMap.students ) && 0 < indexMap.students.length : false

                    this._removeFromCache( localId )
                    this._removeFromArchive( localId )

                    if( hasStudents )
                    {
                        this.eventManager.dispatch( 'on-after-update-merge-student' )
                    }

                    return resolve()

                } )

        } )

    }

    deleteList( list )
    {

        return new Promise( resolve =>
        {

            if( 0 === list.length )
            {
                return resolve()
            }

            let removals = []

            for( let i = 0; i < list.length; i++ )
            {
                let item = list[ i ]
                removals.push( item )
                this.eventManager.dispatch( 'block-sync-for-deletion', item )
            }

            this.database.deleteObjectsList( list )
                .then( () =>
                {
                } )
                .catch( error =>
                {
                    this.logger.cdebug( this.logSign + 'delete', 'deletion of multiple ' + this.type + ' elements failed with: ' + error )
                } )
                .finally( () =>
                {

                    let deletionList = []

                    for( let r in removals )
                    {
                        deletionList.push( { key: removals[ r ], item: this.type } )
                        this._removeFromCache( removals[ r ], r === removals.length - 1 )
                        this._removeFromArchive( removals[ r ], r === removals.length - 1 )
                    }

                    this.database.writeList( 'deletions', deletionList )
                        .then( () =>
                        {

                            return resolve()

                        } )

                } )

        } )

    }

    _processOriginalObject( localId, state, skipCacheRemoval )
    {

        return new Promise( resolve =>
        {

            this.loadItem( localId )
                .then( object =>
                {

                    switch( state )
                    {
                        case true:
                            object.archiveKey = new Date().getFullYear() + '-' + Date.now()
                            object.archived = true
                            if( undefined !== object.referenceKey )
                            {
                                object.referenceKey = 'R-' + object.referenceKey
                            }
                            break
                        case false:
                            delete object.archiveKey
                            object.archiveKey = undefined
                            object.archived = false
                            if( undefined !== object.referenceKey )
                            {
                                let refKey = object.referenceKey.split( /-/g )
                                refKey.shift()
                                object.referenceKey = refKey.join( '-' )
                            }
                            break
                    }

                    this.update( object, object.localId, object.remoteId, object.timestamp, object.localKey, undefined, true )

                    return resolve()

                } )
                .catch( e =>
                {
                    this.logger.cerror( this.logSign, 'failed to lookup original object before archiving: skip.', e )
                } )

        } )

    }


    _processArchiveState( object, state, skipCacheRemoval )
    {

        return new Promise( resolve =>
        {

            if( undefined === skipCacheRemoval )
            {
                if( state === true )
                {
                    this._removeFromCache( object.localId )
                    this._removeFromCache( object.referenceKey )
                }
                else
                {
                    this._removeFromArchive( object.localId )
                    this._removeFromArchive( object.referenceKey )
                }
            }

            if( object.type === 'list'
                && 'combilist' === object.listType )
            {

                return resolve( this._processOriginalObject( object.localId, state, skipCacheRemoval ) )

            }
            else
            {
                /* let clone = JSON.parse( JSON.stringify( object ) )*/

                switch( state )
                {
                    case true:
                        object.archiveKey = new Date().getFullYear() + '-' + Date.now()
                        object.archived = true
                        if( undefined !== object.referenceKey )
                        {
                            object.referenceKey = 'R-' + object.referenceKey
                        }
                        break
                    case false:
                        delete object.archiveKey
                        object.archiveKey = undefined
                        object.archived = false
                        if( undefined !== object.referenceKey )
                        {
                            let refKey = object.referenceKey.split( /-/g )
                            refKey.shift()
                            object.referenceKey = refKey.join( '-' )
                        }
                        break
                }

                this.update( object, object.localId, object.remoteId, object.timestamp, object.localKey, undefined, true )

                return resolve()

            }

        } )

    }

    archive( object )
    {
        return new Promise( resolve =>
        {

            return resolve( this._processArchiveState( object, true ) )

        } )
    }

    unarchive( object )
    {
        return new Promise( resolve =>
        {

            return resolve( this._processArchiveState( object, false ) )

        } )
    }

    multiArchiveState( objects, state )
    {
        return new Promise( resolve =>
        {

            let promises = []

            for( let o in objects )
            {

                if( true === state )
                {
                    this._removeFromCache( objects[ o ].localId )
                    this._removeFromCache( objects[ o ].referenceKey )
                }
                else
                {
                    this._removeFromArchive( objects[ o ].localId )
                    this._removeFromArchive( objects[ o ].referenceKey )
                }

            }

            this.generateSortMap()

            for( let o in objects )
            {

                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {
                        return resolve( this._processArchiveState( objects[ o ], state ) )
                    } )
                } )

            }

            this.functions
                .promiseRunner( promises )
                .then( () =>
                {

                    return resolve()

                } )

        } )
    }

    multiUnarchive( objects )
    {
        return new Promise( resolve =>
        {

            let promises = []
            return resolve( this._processArchiveState( object, false ) )

        } )
    }


    hide( object, status )
    {
        return new Promise( resolve =>
        {
            return resolve( this.setPersonalAttribute( object, 'hiding', 'hidden', status ) )
        } )
    }

    pin( object, status )
    {
        return new Promise( resolve =>
        {
            return resolve( this.setPersonalAttribute( object, 'pinning', 'pinned', status ) )
        } )
    }

    setPersonalAttribute( object, table, flag, status )
    {
        return new Promise( resolve =>
        {

            this.personalAttributes.setState( object, status, table )
                .then( () =>
                {
                    this._refreshCache( object.localId, object )
                    return resolve()
                } )

        } )
    }

}